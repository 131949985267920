<!-- =========================================================================================
    File Name: FormValidationTypes.vue
    Description: Validation types
    ----------------------------------------------------------------------------------------data
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="resource-card">
    <!-- Resource information -->
    <!-- p><pre>data: {{$data }}</pre></p -->

    <!-- Allow Availability - one off
 Block Availability - Lunch, out of office

 Allow from 2020-05-30 04:45 Shown in your timezone (UTC +02:00)to 2020-05-30 05:30
    Block from 2020-05-31 05:45 Shown in your timezone (UTC +02:00)to 2020-05-31 05:45-->
    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/1 lg:w-2/3 mb-base">
        <vx-card>
          <h5 class="mb-2">New Listing</h5>
          <!-- p class="text-black">{{resource_data.resourceId}}</p-->

          <p
            class="text-black"
          >Define which weekly recurring times the resource is available. The working hours apply to all program/careplan/packages and that include the resource.</p>
          <p
            class="text-grey"
          >You can allow (whitelist) or block (blacklist) specific time intervals in addition to your recurring working hours. Specific working hours are useful for holidays, out of office, or irregular schedules that change frequently.</p>
          <form data-vv-scope="resource-card">
            <div class="vx-row">
              <div class="vx-col sm:w-1/1 w-full">
                <vs-input
                  v-validate="'required|alpha_spaces'"
                  data-vv-as="field"
                  name="listingName"
                  label="listingName"
                  v-model="listingName"
                  class="w-full mt-5"
                />
                <span
                  v-show="errors.has('resource-card.listingName')"
                  class="text-danger"
                >{{ errors.first('resource-card.listingName') }}</span>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col sm:w-1/1 w-full">
                <vs-input
                  v-validate="'required|alpha_spaces'"
                  data-vv-as="field"
                  name="locationName"
                  label="locationName"
                  v-model="locationName"
                  class="w-full mt-5"
                />
                <span
                  v-show="errors.has('resource-card.locationName')"
                  class="text-danger"
                >{{ errors.first('resource-card.locationName') }}</span>
              </div>
            </div>

            <!-- t, gType, n, timeZoneH, timeZoneM-->

            <vs-divider></vs-divider>
            <div class="vx-row">
              <div class="vx-col sm:w-1/4 w-full">
                <vs-input
                  v-validate="'required|min:1|max:6|numeric'"
                  data-vv-as="field"
                  name="durationPeriod"
                  label="durationPeriod"
                  v-model="durationHelper.value"
                  class="w-full mt-5"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('resource-card.durationPeriod')"
                >{{ errors.first('resource-card.durationPeriod') }}</span>
              </div>

              <div class="vx-col sm:w-1/4 w-full">
                <vs-input
                  v-validate="'required|min:1|max:6|numeric'"
                  data-vv-as="field"
                  name="futureBookingLimit"
                  label="futureBookingLimit"
                  v-model="futureBookingLimitHelper.value"
                  class="w-full mt-5"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('resource-card.futureBookingLimit')"
                >{{ errors.first('resource-card.futureBookingLimit') }}</span>
              </div>
              <div class="vx-col sm:w-1/4 w-full">
                <vs-input
                  v-validate="'required|min:1|max:6|numeric'"
                  data-vv-as="field"
                  name="minCancellationNotice"
                  label="minCancellationNotice"
                  v-model="minCancellationNoticeHelper.value"
                  class="w-full mt-5"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('resource-card.minCancellationNotice')"
                >{{ errors.first('resource-card.minCancellationNotice') }}</span>
              </div>
              <div class="vx-col sm:w-1/4 w-full">
                <vs-input
                  v-validate="'required|min:1|max:6|numeric'"
                  data-vv-as="field"
                  name="minBookingNotice"
                  label="minBookingNotice"
                  v-model="minBookingNoticeHelper.value"
                  class="w-full mt-5"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('resource-card.minBookingNotice')"
                >{{ errors.first('resource-card.minBookingNotice') }}</span>
              </div>
            </div>
            <vs-divider></vs-divider>
            <vs-button
              @click="updateResourceBasicData(datetimeFrom, datetimeTo)"
              class="text-center font-semibold ml-2"
            >Update</vs-button>

            <vs-divider></vs-divider>
            <!-- div class="vx-row"> Fix issue here
              <div class="vx-col sm:w-1/2 w-full">
                <label class="text-sm opacity-75">Buffer in Minutes</label>
                <vs-input
                  v-validate="'required|min:1|max:6|numeric'"
                  data-vv-as="field"
                  name="buffer"
                  v-model="buffer"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('resource-card.buffer')"
                >{{ errors.first('resource-card.buffer') }}</span>
              </div>
              <div class="vx-col sm:w-1/2 w-full"></div>
            </div>
            <vs-divider></vs-divider>
            <div class="vx-row">
              <div class="vx-col sm:w-1/1 w-full">
                <label class="text-sm opacity-75">bookableResource</label>
                <v-select
                  v-model="bookableResource_local"
                  :clearable="false"
                  :options="bookableResource"
                  v-validate="'required'"
                  name="bookableResource"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('resource-card.bookableResource')"
                >{{ errors.first('resource-card.bookableResource') }}</span>
              </div>
            </div-->
            <vs-divider></vs-divider>

            <label class="text-sm opacity-75">Start and End Time</label>
            <div class="vx-row">
              <div class="vx-col sm:w-1/4 w-full">
                <v-select
                  v-model="selectedDay"
                  :options="daysOpeningHelper"
                  return-object
                  single-line
                  v-validate="'required'"
                  name="dayInWeek"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('dayInWeek')"
                >{{ errors.first('dayInWeek') }}</span>
              </div>

              <div class="vx-col sm:w-1/4 w-full">
                <flat-pickr :config="configdateTimePicker" v-model="startOpeningTime" />
              </div>

              <div class="vx-col sm:w-1/4 w-full">
                <flat-pickr :config="configdateTimePicker" v-model="endOpeningTime" />
              </div>

              <div class="vx-col sm:w-1/4 w-full">
                <vs-button
                  @click="updateResourceWorkingHours(startOpeningTime, endOpeningTime, selectedDay.id)"
                  class="text-center font-semibold ml-2"
                >Add</vs-button>
              </div>
            </div>

            <vs-divider></vs-divider>

            <div class="vx-row">
              <div class="vx-col sm:w-1/4 w-full">
                <v-select
                  v-model="openCloseBlockSelected"
                  :options="openCloseBlock"
                  return-object
                  single-line
                  v-validate="'required'"
                  name="openCloseBlock"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('openCloseBlock')"
                >{{ errors.first('openCloseBlock') }}</span>
              </div>
              <div class="vx-col sm:w-1/4 w-full">
                <flat-pickr
                  :config="configdateTimePicker2"
                  v-model="datetimeFrom"
                  placeholder="From"
                />
              </div>

              <div class="vx-col sm:w-1/4 w-full">
                <flat-pickr :config="configdateTimePicker2" v-model="datetimeTo" placeholder="To" />
              </div>

              <div class="vx-col sm:w-1/4 w-full">
                <vs-button
                  @click="updateResourceAvailabilityIntervals(datetimeFrom, datetimeTo, openCloseBlockSelected.value)"
                  class="text-center font-semibold ml-2"
                >Add</vs-button>
              </div>
            </div>

            <vs-divider></vs-divider>
            <!-- div
              class="vx-row"
              v-for="daysHelper in daysOpeningHelper"
              :key="daysHelper.id"
            >{{ daysHelper.label}} - {{ daysHelper.startTime}} - {{ daysHelper.endTime}}</div-->
            <div
              class="vx-row"
              v-for="(ai, indexRow) in availabilityIntervals"
              :key="indexRow"
            >{{ ai.startDay}} - {{ ai.startMonth}} - {{ ai.startYear}}</div>
            <vs-divider></vs-divider>Current specific working hours
            <br />Block from 2020-06-30 04:45 Shown in your timezone (UTC +02:00)to 2020-07-22 04:45
            <br />Allow from 2020-06-25 02:30 Shown in your timezone (UTC +02:00)to 2020-06-25 03:45
            <br />
          </form>
        </vx-card>
      </div>
    </div>

    <pre>{{ selected }}</pre>
    <br />
  </div>
</template>

<script>
import axios from "@/axios.js";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

// Store Module
import moduleResource from "@/store/resource/moduleResource.js";
const HMinderEndPoint = "http://localhost:8090/";

export default {
  components: {
    vSelect,
    flatPickr
  },
  data() {
    return {
      //data_local: JSON.parse(JSON.stringify(this.data)),
      openCloseBlockSelected: null,
      selectedDay: null,
      availabilityIntervals: [],
      recurringWorkingHours: [],
      settingList: null,
      listingName: "",
      locationName: "",
      speciality: "Operating Room",
      resource_data: null,
      datetime: "",
      datetimeFrom: "",
      datetimeTo: "",
      startTime: null,
      endTime: null,
      daysOpeningHelper_local: "Monday",
      startOpeningTime: "09:00",
      endOpeningTime: "19:00",
      configdateTimePicker: {
        enableTime: true,
        enableSeconds: false,
        noCalendar: true,
        time_24hr: true,
        minTime: "09:00",
        maxTime: "17:30",
        dateFormat: "H:i",
        defaultDate: "09:00",
        inline: false
      },
      configdateTimePicker2: {
        enableTime: true,
        dateFormat: "d-m-Y H:i"
      },
      capacity: {
        variables: [
          { label: "hourPerDay", value: 8.5, capacityId: 1 },
          { label: "hourBreak", value: 1, capacityId: 1 },
          { label: "nonCustomerMeetingTime", value: 0.5, capacityId: 1 },
          { label: "trainingConferenceDaysPerYear", value: 5, capacityId: 1 },
          {
            label: "weekEndPublicHolidaysDaysPerYear",
            value: 110,
            capacityId: 1
          },
          { label: "vacationDaysPerYear", value: 26, capacityId: 1 }
        ],
        notes: [
          "Net 7hr per day x 60 min/hr = 420 min/day",
          "420 min/day x net 224 working day/yr = 94080 min/yr"
        ]
      },
      consumables: [
        { label: "Hip implant", value: 4500, quantity: 1 },
        { label: "Custom hip pack", value: 350, quantity: 1 },
        { label: "Saw blade", value: 55, quantity: 2 },
        { label: "Suture material", value: 18, quantity: 4 }
      ],
      type: "",
      name: "",
      futureBookingLimit_local: "days",
      minCancellationNotice_local: "days",
      minBookingNotice_local: "days",
      daysHelper_local: "Monday",
      durationPeriod_local: "hours",
      bookableResource_local: "none",
      buffer_local: "10",
      resources: [],
      selected: [],
      title: "X-Ray Technician",
      department: "Imaging",
      users: [
        { label: "salary", value: "32000", costTypeId: 1 },
        { label: "supervision", value: "2", costTypeId: 2 },
        { label: "benefits", value: "8320", costTypeId: 3 },
        { label: "itSupport", value: "1000", costTypeId: 4 },
        { label: "assistan admin support", value: "0", costTypeId: 5 },
        { label: "trainingNTravel", value: "1000", costTypeId: 6 },
        { label: "officeExpenses", value: "0", costTypeId: 7 },
        { label: "malpracticeInsurance", value: "0", costTypeId: 8 },
        { label: "miscellaneous", value: "0", costTypeId: 9 }
      ],
      quantityOld: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" }
      ],
      openCloseBlock: [
        { label: "Open Block", value: "yes" },
        { label: "Close Block", value: "no" }
      ],
      bookableResource: [
        { label: "Doctor1", value: "D1" },
        { label: "Doctor2", value: "D2" },
        { label: "Doctor3", value: "D3" }
      ],
      frequencyHelper: [
        { label: "Morning", value: "morning" },
        { label: "Afternoon", value: "afternoon" },
        { label: "Evening", value: "evening" }
      ],
      daysOpeningHelper: [
        { label: "Monday", id: "1" },
        { label: "Tuesday", id: "2" },
        { label: "Wednesday", id: "3" },
        { label: "Thursday", id: "4" },
        { label: "Friday", id: "5" },
        { label: "Saturday", id: "6" },
        { label: "Sunday", id: "7" }
      ],
      durationHelper: [
        { label: "minutes", value: "1" },
        { label: "hours", value: "2" },
        { label: "days", value: "3" }
      ],
      futureBookingLimitHelper: [
        { label: "days", value: "1", min: "1", max: "30" },
        { label: "weeks", value: "2", min: "1", max: "4" },
        { label: "months", value: "3", min: "1", max: "3" }
      ],
      minCancellationNoticeHelper: [
        { label: "minutes", value: "1", min: "1", max: "30" },
        { label: "hours", value: "2", min: "1", max: "4" },
        { label: "days", value: "3", min: "1", max: "3" }
      ],
      minBookingNoticeHelper: [
        { label: "minutes", value: "1", min: "30" },
        { label: "hours", value: "2", min: "1" },
        { label: "days", value: "3", min: "3" }
      ],
      buffer: [
        { label: "5 minutes", value: "5" },
        { label: "10 minutes", value: "10" },
        { label: "15 minutes", value: "15" },
        { label: "30 minutes", value: "30" },
        { label: "45 minutes", value: "45" },
        { label: "60 minutes", value: "60" },
        { label: "75 minutes", value: "75" },
        { label: "90 minutes", value: "90" }
      ],
      a: [
        { value: "4a55eff3-1e0d-4a81-9105-3ddd7521d642", display: "Jamsheer" },
        { value: "644838b3-604d-4899-8b78-09e4799f586f", display: "Muhammed" },
        { value: "b6ee537a-375c-45bd-b9d4-4dd84a75041d", display: "Ravi" },
        { value: "e97339e1-939d-47ab-974c-1b68c9cfb536", display: "Ajmal" },
        { value: "a63a6f77-c637-454e-abf2-dfb9b543af6c", display: "Ryan" },
        { value: "a63a6f77-c637-454e-abf2-dfb9b543af6c", display: "Sofiane" }
      ],
      b: [
        {
          value: "4a55eff3-1e0d-4a81-9105-3ddd7521d642",
          display: "Jamsheer",
          $$hashKey: "008"
        },
        {
          value: "644838b3-604d-4899-8b78-09e4799f586f",
          display: "Muhammed",
          $$hashKey: "009"
        },
        {
          value: "b6ee537a-375c-45bd-b9d4-4dd84a75041d",
          display: "Ravi",
          $$hashKey: "00A"
        },
        {
          value: "e97339e1-939d-47ab-974c-1b68c9cfb536",
          display: "Ajmal",
          $$hashKey: "00B"
        },
        { value: "a63a6f77-c637-454e-abf2-dfb9b543af6c", display: "Ryan" },
        { value: "a63a6f77-c637-454e-abf2-dfb9b543af6c", display: "Sofiane" }
      ]
    };
  },
  //listingName,locationName,durationPeriod_local,futureBookingLimit_local,minCancellationNotice_local,minBookingNotice_local,buffer_local,bookableResource_local

  computed: {
    validateForm() {
      return !this.errors.any();
    }
  },
  methods: {
    comparer(otherArray) {
      return function(current) {
        return (
          otherArray.filter(function(other) {
            return (
              other.value == current.value && other.display == current.display
            );
          }).length == 0
        );
      };
    },
    setSelected() {
      alert(
        "Label: " + this.selectedDay.label + "; Value: " + this.selectedDay.id
      );
      console.log("Label: ", this.selectedDay.label);
      console.log("Value: ", this.selectedDay.id);
    },
    updateResourceBasicData(t, gType, n, timeZoneH, timeZoneM) {
      var newResource = {
        type: t,
        groupType: gType,
        name: n,
        resourceId: this.$route.params.resource_id,
        timeZoneHour: timeZoneH,
        timeZoneMinute: timeZoneM
      };
      console.log(newResource);
    },
    updateResourceWorkingHours(hFrom, hTo, sDay) {
      console.log("sDay :" + sDay);
      var d = parseInt(sDay) - 1;
      console.log("sday number:" + d);

      var wTimePerDayStartHour = ["", "", "", "", "", "", ""];
      var wTimePerDayStartMinute = ["", "", "", "", "", "", ""];
      var wTimePerDayEndHour = ["", "", "", "", "", "", ""];
      var wTimePerDayEndMinute = ["", "", "", "", "", "", ""];

      //stringMonth="0"+month
      //const strNumber = '9';
      //console.log(strNumber.padStart(2, '0')); // 09
      //var number  = strNumber.replace(/^0+/, '');
      //var strNumber = "00012";
      //var number = Number(strNumber);
      //console.log(number); //12

      var timeFromArray = hFrom.split(":");
      var timeToArray = hTo.split(":");
      wTimePerDayStartHour[d] = timeFromArray[0];
      wTimePerDayStartMinute[d] = timeFromArray[1];
      wTimePerDayEndHour[d] = timeToArray[0];
      wTimePerDayEndMinute[d] = timeToArray[1];

      const updateResourceWorkingTime = {
        resourceId: this.$route.params.resource_id,
        workingTimePerDayStartHour: wTimePerDayStartHour,
        workingTimePerDayStartMinute: wTimePerDayStartMinute,
        workingTimePerDayEndHour: wTimePerDayEndHour,
        workingTimePerDayEndMinute: wTimePerDayEndMinute
      };

      console.log(updateResourceWorkingTime);
      var newResourceData = JSON.parse(JSON.stringify(this.resource_data));
      newResourceData.workingTimePerDayStartHour[d] = timeFromArray[0];
      newResourceData.workingTimePerDayStartMinute[d] = timeFromArray[1];
      newResourceData.workingTimePerDayEndHour[d] = timeToArray[0];
      newResourceData.workingTimePerDayEndMinute[d] = timeToArray[1];
      console.log("START newResourceData");
      console.log(newResourceData);
      console.log("END newResourceData ");

      var postURL = "resourcePersonnel/updateResourceWorkingHours";
      var config = { headers: { "Content-Type": "application/json" } };
      axios
        .post(HMinderEndPoint + postURL, newResourceData, config)
        .then(response => {
          console.log("postURL");
          console.log(response);
          //console.log("resourceId : " + response.data.resourceId);
          //this.appointmentConfirmed = true;
          //return this.$router.push('/apps/preventiveHealth/booking-success').catch(() => {})
        });
    },
    updateResourceAvailabilityIntervals(dateFrom, dateTo, selection) {
      const _this = this;

      var currentResourceData = JSON.parse(JSON.stringify(this.resource_data));
      var dataTimeFromArray = _this
        .$moment(dateFrom, "DD-MM-YYYY HH:mm")
        .toArray();
      var dataTimeToArray = _this.$moment(dateTo, "DD-MM-YYYY HH:mm").toArray();
      //this.fetch_resource_data(this.$route.params.resource_id)
      var interval = {
        startYear: dataTimeFromArray[0],
        startMonth: dataTimeFromArray[1] + 1,
        startDay: dataTimeFromArray[2],
        startHour: dataTimeFromArray[3],
        startMinute: dataTimeFromArray[4],
        startSecond: dataTimeFromArray[5],
        endYear: dataTimeToArray[0],
        endMonth: dataTimeToArray[1] + 1,
        endDay: dataTimeToArray[2],
        endHour: dataTimeToArray[3],
        endMinute: dataTimeToArray[4],
        endSecond: dataTimeToArray[5],
        availability: selection
      };
      this.availabilityIntervals = currentResourceData.availabilityIntervals;
      this.availabilityIntervals.push(interval);
      console.log(this.availabilityIntervals);

      var postURL = "resourcePersonnel/updateResourceAvailabilityIntervals";
      var config = { headers: { "Content-Type": "application/json" } };
      axios
        .post(HMinderEndPoint + postURL, this.availabilityIntervals, config)
        .then(response => {
          console.log("postURL");
          console.log(response);
          //console.log("resourceId : " + response.data.resourceId);
          //this.appointmentConfirmed = true;
          //return this.$router.push('/apps/preventiveHealth/booking-success').catch(() => {})
        });
    },
    updateByUUID(r) {
      console.log(r);

      //this.fetch_resource_data(this.$route.params.resource_id)
      var newResource = {
        allData: "string",
        attr01: "string",
        attr02: "string",
        availabilityIntervals: r,
        groupType: "string",
        name: "string",
        resourceId: this.$route.params.resource_id,
        timeZoneHour: 0,
        timeZoneMinute: 0,
        type: "string"
      };
      console.log(newResource);

      var postURL = "resourcePersonnel/updateByAttr02";
      var config = {
        headers: { "Content-Type": "application/json" }
      };
      axios
        .post(HMinderEndPoint + postURL, newResource, config)
        .then(response => {
          console.log("postURL");
          console.log(response);
          //console.log("resourceId : " + response.data.resourceId);
          //this.appointmentConfirmed = true;
          //return this.$router.push('/apps/preventiveHealth/booking-success').catch(() => {})
        });
    },
    save_resource(r) {
      //pass existing object to save all relevant fields
      console.log(r);
      var postURL = "resourcePersonnel/save";

      var newResource = {
        allData: "save_resource",
        availabilityIntervals: r
      };
      console.log(newResource);
      var config = {
        headers: { "Content-Type": "application/json" }
      };
      axios
        .post(HMinderEndPoint + postURL, newResource, config)
        .then(response => {
          console.log("postURL");
          console.log(response);
          //console.log("resourceId : " + response.data.resourceId);
          //this.appointmentConfirmed = true;
          //return this.$router.push('/apps/preventiveHealth/booking-success').catch(() => {})
        });
    },
    addRecurringWorkingHours(hFrom, hTo, sDay) {
      var d = parseInt(sDay);
      console.log(
        "hFrom: " +
          this.startOpeningTime +
          " - hTo: " +
          this.endOpeningTime +
          " on " +
          d
      );
      console.log("hFrom: " + hFrom + " - hTo: " + hTo + " on " + d);
      console.log("this.selectedDay");
      console.log(this.selectedDay);
      console.log(this.resource_data);

      var wTimePerDayStartHour = ["", "", "", "", "", "", ""];
      var wTimePerDayStartMinute = ["", "", "", "", "", "", ""];
      var wTimePerDayEndHour = ["", "", "", "", "", "", ""];
      var wTimePerDayEndMinute = ["", "", "", "", "", "", ""];

      var timeFromArray = hFrom.split(":");
      var timeToArray = hTo.split(":");
      console.log(timeFromArray);
      wTimePerDayStartHour[d] = timeFromArray[0];
      wTimePerDayStartMinute[d] = timeFromArray[1];
      wTimePerDayEndHour[d] = timeToArray[0];
      wTimePerDayEndMinute[d] = timeToArray[1];

      const updateResourceWorkingTime = {
        resourceId: this.$route.params.resource_id,
        workingTimePerDayStartHour: wTimePerDayStartHour,
        workingTimePerDayStartMinute: wTimePerDayStartMinute,
        workingTimePerDayEndHour: wTimePerDayEndHour,
        workingTimePerDayEndMinute: wTimePerDayEndMinute
      };

      var postURL = "resourcePersonnel/updateByAttr02";
      var config = { headers: { "Content-Type": "application/json" } };
      axios
        .post(HMinderEndPoint + postURL, updateResourceWorkingTime, config)
        .then(response => {
          console.log("postURL");
          console.log(response);
          //console.log("resourceId : " + response.data.resourceId);
          //this.appointmentConfirmed = true;
          //return this.$router.push('/apps/preventiveHealth/booking-success').catch(() => {})
        });
    },
    addSpecificHours(dateFrom, dateTo) {
      const _this = this;

      var dataTimeFromArray = _this
        .$moment(dateFrom, "DD-MM-YYYY HH:mm")
        .toArray();
      var dataTimeToArray = _this.$moment(dateTo, "DD-MM-YYYY HH:mm").toArray();
      //this.fetch_resource_data(this.$route.params.resource_id)
      var interval = {
        startYear: dataTimeFromArray[0],
        startMonth: dataTimeFromArray[1] + 1,
        startDay: dataTimeFromArray[2],
        startHour: dataTimeFromArray[3],
        startMinute: dataTimeFromArray[4],
        startSecond: dataTimeFromArray[5],
        endYear: dataTimeToArray[0],
        endMonth: dataTimeToArray[1] + 1,
        endDay: dataTimeToArray[2],
        endHour: dataTimeToArray[3],
        endMinute: dataTimeToArray[4],
        endSecond: dataTimeToArray[5],
        availability: "yes",
        timeZoneHour: 0,
        timeZoneMinute: 0
      };

      this.availabilityIntervals.push(interval);
      console.log(this.availabilityIntervals);
      //this.save_resource(this.availabilityIntervals);
      this.updateByUUID(this.availabilityIntervals);
    },
    fetch_resource_data(resourceId) {
      this.$store
        .dispatch("resource/fetchResource", resourceId)
        .then(res => {
          console.log(res.data);
          this.resource_data = res.data;
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.resource_not_found = true;
            return;
          }

          if (err.response.status === 500) {
            this.resource_not_found = true;
            return;
          }
          console.error(err);
        });
    },
    fetchSettings(id) {
      console.log("fetchSettings  ID : " + id);

      var config = {
        headers: { "Access-Control-Allow-Origin": "*" }
      };
      axios
        .get("http://localhost:8090/company/getSettings?cnr=G7H8IJ", config)
        .then(response => {
          console.log("START getSettings");
          this.settingList = response.data;
          console.log(response.data);
          //this.daysOpeningHelper = this.settingList.openingHours;
          console.log(this.daysOpeningHelper);
          this.durationHelper = this.settingList.duration;
          console.log(this.durationHelper);
          this.futureBookingLimitHelper = this.settingList.minBookingNotice;
          this.minCancellationNoticeHelper = this.settingList.minCancellationNotice;
          this.minBookingNoticeHelper = this.settingList.minBookingNotice;
          this.buffer = this.settingList.bufferTime;

          console.log("END getSettings size" + this.settingList);
        });
    },
    createSettings() {
      var dataBook = {
        automated: true,
        reminder: true,
        bookableResources: [
          {
            system: "string",
            type: {
              text: "string"
            },
            use: "string",
            value: "string"
          }
        ],
        bufferTime: 0,
        duration: {
          id: "string",
          label: "string",
          max: "string",
          min: "string",
          unit: "string",
          value: "string"
        },
        futureBookingLimit: {
          id: "string",
          label: "string",
          max: "string",
          min: "string",
          unit: "string",
          value: "string"
        },
        minBookingNotice: {
          id: "string",
          label: "string",
          max: "string",
          min: "string",
          unit: "string",
          value: "string"
        },
        minCancellationNotice: {
          id: "string",
          label: "string",
          max: "string",
          min: "string",
          unit: "string",
          value: "string"
        },
        openingHours: [
          {
            endTime: "string",
            id: "string",
            label: "string",
            startTime: "string"
          }
        ]
      };

      var config = {
        headers: { "Content-Type": "application/json" }
      };
      console.log(this.$data);
      //axios.post(HMinderEndPoint + postURL,"4a93ac04-1115-4634-8664-db27272f9323",config)
      axios
        .post(
          "http://localhost:8090/company/addSettings?cnr=G7H8IJ",
          dataBook,
          config
        )
        .then(response => {
          console.log("addSettings");
          console.log(response);
          this.appointmentId = response.data;
          console.log("addSettings");

          return this.$router
            .push("/apps/preventiveHealth/booking-success")
            .catch(() => {});
        });
    },
    handleSelected(tr) {
      this.$vs.notify({
        title: `Selected ${tr.label}`,
        text: `Email: ${tr.value}`
      });
    },
    capitalize(str) {
      return str.slice(0, 1).toUpperCase() + str.slice(1, str.length);
    },
    save_changes() {
      /* eslint-disable */
      if (!this.validateForm) return;

      // Here will go your API call for updating data
      // You can get data in "this.data_local"

      /* eslint-enable */
    },
    reset_data() {
      this.data_local = JSON.parse(JSON.stringify(this.data));
    },
    update_avatar() {
      // You can update avatar Here
      // For reference you can check dataList example
      // We haven't integrated it here, because data isn't saved in DB
    }
  },
  created() {
    //this.create_booking_details(this.$route.params.pkg_id);
    this.settingList = this.fetchSettings(1);

    this.availabilityIntervals = [];
    console.log(this.availabilityIntervals);
    const _this = this;
    this.datetime = "18-06-2020 10:30";
    var dataTimeFromArray = _this
      .$moment(this.datetime, "DD-MM-YYYY HH:mm")
      .toArray();
    var dataTimeToArray = _this
      .$moment(this.datetime, "DD-MM-YYYY HH:mm")
      .toArray();

    var dateFrom = {
      startYear: dataTimeFromArray[0],
      startMonth: dataTimeFromArray[1] + 1,
      startDay: dataTimeFromArray[2],
      startHour: dataTimeFromArray[3],
      startMinute: dataTimeFromArray[4],
      startSecond: dataTimeFromArray[5],
      endYear: dataTimeToArray[0],
      endMonth: dataTimeToArray[1] + 1,
      endDay: dataTimeToArray[2],
      endHour: dataTimeToArray[3],
      endMinute: dataTimeToArray[4],
      endSecond: dataTimeToArray[5],
      availability: "yes",
      timeZoneHour: 0,
      timeZoneMinute: 0
    };

    console.log(dateFrom);

    // Register Module UserManagement Module
    if (!moduleResource.isRegistered) {
      this.$store.registerModule("moduleResource", moduleResource);
      moduleResource.isRegistered = true;
    }

    console.log("fetch_resource_data");
    console.log("resourceId: " + this.$route.params.resource_id);
    console.log(this.$route.params.resource_id);
    this.fetch_resource_data(this.$route.params.resource_id);
    //a is the full week day
    //b is the day already allocated
    var onlyInA = this.a.filter(this.comparer(this.b));
    var onlyInB = this.b.filter(this.comparer(this.a));

    var result = onlyInA.concat(onlyInB);

    console.log(result);

    //this.createSettings();
  }
};
</script>
